<template>
  <div>
    <CSpinner v-if="$apollo.queries.revenueAccount.loading" color="info"/>
    <CAlert
        v-if="error"
        color="danger"
        closeButton
        @update:show="removeError">
      {{error}}
    </CAlert>
    <DeleteModal
        entity="revenue-account"
        :name="itemName"
        :nid="revenueAccount.nid"
        :show.sync="showDeleteItemModal"
        v-on:delete="deleteItem"
        v-on:cancel-delete="toggleDeleteItemModal"
    />
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol class="text-left" sm="2">
            <DetailsButtonGroup
                v-on:save="saveItem"
                v-on:cancel="resetItem"
                v-on:delete-prompt="toggleDeleteItemModal"
                v-on:close="$router.push({path: '/billing/revenue-accounts'})"
            />
          </CCol>
          <CCol sm="8">
            <h2 data-sel="txt-title">{{itemName}}</h2>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <RevenueAccountForm
            v-bind:revenueAccountIn="revenueAccountEdited"
            v-on:update-item="updateItem"
            v-on:error="catchError"
        />
      </CCardBody>
      <CCardFooter
          class="text-left">
        <DetailsButtonGroup
            v-on:save="saveItem"
            v-on:cancel="resetItem"
            v-on:delete-prompt="toggleDeleteItemModal"
            v-on:close="$router.push({ path: '/billing/revenue-accounts'})"
          />
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import gql from "graphql-tag";
import DetailsButtonGroup from "@/components/common/DetailsButtonGroup";
import DeleteModal from "@/components/common/DeleteModal";
import RevenueAccountForm from "@/apps/billing/components/RevenueAccountForm";

const queryRevenueAccount = gql`
query revenueAccount($nid: ID!) {
  revenueAccount(nid: $nid) {
    nid
    name
    description
    revenueAccountNumber
    clientLocation
    contractDomain
    productGroups {
      nid 
      name
    }
  }
}
`
const updateRevenueAccount = gql`
  mutation updateRevenueAccount($nid: ID!, $revenueAccount: RevenueAccountInput!){
    updateRevenueAccount(nid: $nid, revenueAccount: $revenueAccount){
        nid
    }
  }
`
const deleteRevenueAccount = gql`
  mutation deleteRevenueAccount($nid: ID!){
    deleteRevenueAccount(nid: $nid)
  }
`
const clonedeep = require('lodash.clonedeep')

export default {
  name: "RevenueAccount",
  components: {
    DetailsButtonGroup,
    DeleteModal,
    RevenueAccountForm
  },
  data() {
    return {
      error: null,
      showDeleteItemModal: false,
      revenueAccount: {},
      revenueAccountEdited: {},
      revenueAccountSave: {}
    }
  },
  methods: {
    removeError(hide){
      if(!hide){
        this.error = null
      }
    },
    catchError(event){
      this.error = event
    },
    updateItem(data){
      this.revenueAccountSave = data
    },
    saveItem(){
      const {nid, __typename, ...updatedObj} = this.revenueAccountSave;
      this.$apollo.mutate({
        mutation: updateRevenueAccount,
        variables: {
          nid: nid,
          revenueAccount: updatedObj
        }
      })
      .then(res => {
        this.$emit('save-item', this.revenueAccountEdited);
        this.$store.commit('set', ['addModalShow', false]);
        this.$store.commit('set', ['refetchData', true]);
        this.$router.push({ path: '/billing/revenue-accounts'});
      })
      .catch(error => {
        console.error(error);
        this.error = error.message;
      })
    },
    toggleDeleteItemModal(){
      this.showDeleteItemModal = !this.showDeleteItemModal;
    },
    resetItem(){
      this.revenueAccountEdited = clonedeep(this.$apolloData.data.revenueAccount);
    },
    deleteItem(){
      this.$apollo.mutate({
        mutation: deleteRevenueAccount,
        variables: {
          nid: this.revenueAccount.nid
        }
      })
      .then(res => {
        this.$emit('delete-revenue-account', this.revenueAccount.nid);
        this.$store.commit('set', ['refetchData', true]);
        this.$router.push({ path: '/billing/revenue-accounts'});
      })
      .catch(error => {
        console.error(error);
        this.error = error.message;
      })
    }
  },
  computed: {
    allowEdit() {
      return this.$store.state.allowEdit
    },
    itemName(){
      return this.revenueAccountEdited.name
    }
  },
  apollo: {
    revenueAccount: {
      query: queryRevenueAccount,
      error(error) {
        this.error = error.message;
      },
      variables() {
        return {
          nid: this.$route.params.nid
        }
      },
      result ({ data, loading, networkStatus }) {
        this.resetItem();
      },
      fetchPolicy: "cache-and-network"
    }
  }
}
</script>
